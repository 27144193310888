import React from "react";
import { useMount } from "react-use";

import SEO from "../components/MetaSeo";
import MiniHero from "../components/MiniHero";
import Layout from "../components/layout";
import WhatsappButton from "../components/WhatsappButton";
import ImgSVG from "../components/treact/images/email-illustration.svg";

const Obrigado = () => {
  const title = "Obrigado";
  const slug = "obrigado";

  useMount(() => {
    console.log("useMount", typeof window, window.gtag);
    if (typeof window !== "undefined" && window.gtag) {
      console.log("conversion");
      window.gtag("event", "conversion", {
        send_to: "AW-992790442/1x3VCMSVl-oBEKqPs9kD"
      });
    }
  });

  return (
    <Layout>
      <SEO title={title} pathname={slug} />
      <MiniHero name={title} />

      <div className="container mx-auto">
        <section className="text-gray-700 body-font">
          <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
            <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
              <img
                className="object-cover object-center rounded"
                alt="hero"
                src={ImgSVG}
              />
            </div>
            <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
              <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
                Obrigado!
              </h1>
              <p className="mb-8 leading-relaxed">
                Em breve retornamos o contato!
              </p>
            </div>
          </div>
        </section>
      </div>

      <WhatsappButton />
    </Layout>
  );
};

export default Obrigado;
